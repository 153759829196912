"use client";

import { motion } from 'framer-motion';
import { FiChevronDown } from 'react-icons/fi';

export default function ScrollIndicator() {
  return (
    <div className="flex justify-center w-full -mt-20 mb-12">
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ 
          opacity: [0.4, 1, 0.4],
          y: [0, 8, 0]
        }}
        transition={{ 
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
        className="cursor-pointer"
        onClick={() => {
          const profilesSection = document.querySelector('#latest-profiles');
          profilesSection?.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        <FiChevronDown 
          className="text-[#5711FB] w-8 h-8 hover:text-[#4600d1] transition-colors" 
          aria-label="Scroll to profiles"
        />
      </motion.div>
    </div>
  );
} 