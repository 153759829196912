"use client";

import { motion } from "framer-motion";
import Link from "next/link";
import { FiArrowRight } from "react-icons/fi";
import { useState, useEffect, Suspense } from 'react';
import Cookies from 'js-cookie';
import CookieNotice from "./components/CookieNotice";
import { useSearchParams } from 'next/navigation';
import ScrollIndicator from './components/ScrollIndicator';
import posthog from 'posthog-js';

posthog.init('phc_9jAF2bSDaHVVMI7chxTzlfPKFhNum487mGPN0exqEa7',
  {
      api_host: 'https://eu.i.posthog.com',
      person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
  }
)

// Add type for profile data
type ProfileData = {
  profile: {
    basic_info: {
      full_name: string;
      professional_title: string;
      profile_image_name?: string;
      personal_details?: {
        location?: string;
      };
    };
    skills: {
      core_skills: string[];
    };
  };
};

// Add type for profiles object
const profilesData: Record<string, ProfileData> = {
  balach: require("@/app/data/balach.json"),
  bernd: require("@/app/data/bernd.json"),
  christian: require("@/app/data/christian.json"),
  juan: require("@/app/data/juan.json"),
  leon: require("@/app/data/leon.json"),
  luke: require("@/app/data/luke.json"),
  noah: require("@/app/data/noah.json"),
  paulo: require("@/app/data/melo.json"),
  tammy: require("@/app/data/tammy.json"),
  veronica: require("@/app/data/veronica.json"),
};

// Add type for profile object
type Profile = {
  id: string;
  name: string;
  image: string;
  role: string;
  location: string;
  skills: string[];
};

// Update getProfiles function with return type
const getProfiles = (): { latest: Profile; featured: Profile[] } => {
  // Leon is always the latest profile
  const leon = {
    id: 'leon',
    name: profilesData.leon.profile.basic_info.full_name,
    image: profilesData.leon.profile.basic_info.profile_image_name,
    role: profilesData.leon.profile.basic_info.professional_title,
    location: profilesData.leon.profile.basic_info.personal_details?.location ?? "Amsterdam, Netherlands",
    skills: profilesData.leon.profile.skills.core_skills.slice(0, 3)
  };

  // Get other profiles excluding Leon
  const otherProfilesData = Object.entries(profilesData)
    .filter(([id]) => id !== 'leon')
    .map(([id, data]) => ({
      id,
      name: data.profile.basic_info.full_name,
      image: data.profile.basic_info.hasOwnProperty('profile_image_name') ? data.profile.basic_info.profile_image_name : 'default.avif',
      role: data.profile.basic_info.professional_title,
      location: data.profile.basic_info.personal_details?.location ?? "EU",
      skills: data.profile.skills.core_skills.slice(0, 3)
    }));

  // Randomly select 2 other profiles
  const shuffled = otherProfilesData.sort(() => 0.5 - Math.random());
  const selected = shuffled.slice(0, 4);

  return { latest: leon, featured: selected };
};

const getLastViewedProfile = () => {
  return Cookies.get('lastViewedProfile') || '';
};

// Add type for form submission event
type FormSubmitEvent = React.FormEvent<HTMLFormElement> & {
  target: HTMLFormElement & {
    email: HTMLInputElement;
  };
};

// Assuming you have a component using useSearchParams
function SearchParamsComponent() {
  const searchParams = useSearchParams()
  const profiles = getProfiles();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Store reference from URL params in cookie when component mounts
  useEffect(() => {
    const ref = searchParams.get('ref') || searchParams.get('utm_source') || '';
    if (ref) {
      Cookies.set('reference', ref, { expires: 30 }); // Expires in 30 days
    }
  }, [searchParams]);

  // Function to get stored reference
  const getStoredReference = () => {
    return Cookies.get('reference') || window.location.href;
  };

  // Update form onSubmit handlers with proper event type
  const handleSubmit = async (e: FormSubmitEvent) => {
    e.preventDefault();
    const email = e.target.email.value;

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // we should definitely add more domains to this list
    // see https://knowledge.hubspot.com/forms/what-domains-are-blocked-when-using-the-forms-email-domains-to-block-feature
    const blockedDomains = ['example.com', 'mailinator.com'];
    const domain = email.split('@')[1];

    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }

    if (blockedDomains.includes(domain)) {
      setError('Please use a valid business or personal email');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      posthog.capture('waitlist:register', {lastProfile: getLastViewedProfile()});
      const response = await fetch('https://inspired.app.n8n.cloud/webhook/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Origin': window.location.origin,
        },
        mode: 'cors',
        body: JSON.stringify({
          email: email,
          reference: getStoredReference(),
          lastProfile: getLastViewedProfile(),
          datetime: new Date().toISOString()
        })
      });

      if (!response.ok) {
        posthog.capture('waitlist:register_error', {});
        throw new Error('Failed to join waitlist');
      }
      posthog.capture('waitlist:register_success', {});
      setSuccess(true);
      setError('');
      (e.target as HTMLFormElement).reset();
    } catch (error) {
      setError('Failed to join waitlist. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <motion.section
        id="join-waitlist"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="min-h-[80vh] flex flex-col justify-center items-center text-center px-4 relative overflow-hidden"
      >
        <div className="absolute inset-0 bg-gradient-to-b from-[#5711FB]/5 to-transparent" />

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="relative z-10 max-w-4xl mx-auto"
        >
          <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
            Your Professional Story,
            <span className="text-[#5711FB]"> Beautifully Told</span>
          </h1>
          <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
            Join the next generation of professional networking where your unique skills
            and experiences shine through AI-powered insights.
          </p>

          <motion.div
            className="flex flex-col sm:flex-row gap-4 justify-center items-center"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            {!success ? (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col sm:flex-row gap-4 items-center"
              >
                <div className="relative w-full sm:w-auto">
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    className="px-6 py-3 rounded-full bg-white border border-gray-200 text-gray-900 w-full sm:w-80 focus:outline-none focus:border-[#5711FB] focus:ring-1 focus:ring-[#5711FB]"
                    required
                    disabled={isLoading}
                  />
                  {error && (
                    <motion.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="absolute -bottom-6 left-0 text-red-400 text-sm"
                    >
                      {error}
                    </motion.p>
                  )}
                </div>
                <motion.button
                  type="submit"
                  whileHover={{ scale: isLoading ? 1 : 1.05 }}
                  whileTap={{ scale: isLoading ? 1 : 0.95 }}
                  className={`px-8 py-3 bg-[#5711FB] hover:bg-[#4600d1] rounded-full text-white flex items-center justify-center gap-2 transition-colors w-full sm:w-auto ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center gap-2">
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Joining...
                    </div>
                  ) : (
                    <>Join Beta Waitlist <FiArrowRight /></>
                  )}
                </motion.button>
              </form>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-center"
              >
                <p className="text-green-600 mb-4">
                  Thanks for joining! We'll be in touch soon.
                </p>
                <div className="text-gray-600">
                  <p>Help us spread the word!</p>
                  <div className="flex justify-center gap-4 mt-4">
                    <motion.a
                      href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                        'Just joined the waitlist for @letsroll_ai - the next generation of professional networking! 🚀'
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      whileHover={{ scale: 1.05 }}
                      className="text-[#11A9FB] hover:text-[#5711FB] transition-colors"
                    >
                      Share on Twitter
                    </motion.a>
                    <motion.a
                      href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                        'https://letsroll.ai'
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      whileHover={{ scale: 1.05 }}
                      className="text-[#11A9FB] hover:text-[#5711FB] transition-colors"
                    >
                      Share on LinkedIn
                    </motion.a>
                  </div>
                </div>
              </motion.div>
            )}
          </motion.div>
        </motion.div>
      </motion.section>

      <ScrollIndicator />

      {/* Featured Profiles Grid */}
      <section id="latest-profiles" className="py-20 px-4">
        <div className="container mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-6xl mx-auto bg-white/30 rounded-2xl p-8 backdrop-blur-lg"
          >
            {/* Latest Profile */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              className="mb-16 max-w-2xl mx-auto"
            >
              <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
                Latest Profile
              </h2>
              <Link href={`/profile/${profiles.latest.id}`}>
                <div className="bg-white/90 backdrop-blur-lg rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-shadow p-6 h-full">
                  <div className="flex flex-col md:flex-row gap-6 items-center">
                    <div className="flex-shrink-0">
                      <div className="relative w-32 h-32">
                        <img
                          src={`/profiles/${profiles.latest.image}`}
                          alt={profiles.latest.name}
                          className="w-full h-full object-cover rounded-full"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col justify-center text-center md:text-left">
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">{profiles.latest.name}</h3>
                      <p className="text-gray-600 mb-2">{profiles.latest.role}</p>
                      {profiles.latest.location && (
                        <p className="text-gray-500 text-sm">{profiles.latest.location}</p>
                      )}
                    </div>
                  </div>
                  <div className="mt-6">
                    <div className="flex flex-wrap gap-2 justify-center md:justify-start">
                      {profiles.latest.skills.map(skill => (
                        <span
                          key={skill}
                          className="px-3 py-1 bg-[#5711FB]/10 rounded-full text-sm text-[#5711FB]"
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </Link>
            </motion.div>

            {/* Featured Profiles */}
            <div>
              <h2 className="text-2xl font-bold text-center text-gray-900 mb-8">
                Featured Professionals
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {profiles.featured.map((profile, index) => (
                  <motion.div
                    key={profile.id}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    whileHover={{ y: -10 }}
                    className="h-full"
                  >
                    <Link href={`/profile/${profile.id}`} className="block h-full">
                      <div className="bg-white/90 backdrop-blur-lg rounded-xl overflow-hidden shadow-md p-6 h-full">
                        <div className="flex flex-col md:flex-row gap-6">
                          <div className="flex-shrink-0">
                            <div className="relative w-32 h-32">
                              <img
                                src={`/profiles/${profile.image}`}
                                alt={profile.name}
                                className="w-full h-full object-cover rounded-full"
                                onError={(e) => {
                                  const target = e.target as HTMLImageElement;
                                  target.onerror = null; // Prevent infinite loop
                                  target.src = '/profiles/default.avif';
                                }}
                              />
                            </div>
                          </div>
                          <div className="flex flex-col justify-center">
                            <h3 className="text-xl font-semibold text-gray-900 mb-2">{profile.name}</h3>
                            <p className="text-gray-600 mb-2">{profile.role}</p>
                            {profile.location && (
                              <p className="text-gray-500 text-sm">{profile.location}</p>
                            )}
                          </div>
                        </div>
                        <div className="mt-6">
                          <div className="flex flex-wrap gap-2">
                            {profile.skills.map(skill => (
                              <span
                                key={skill}
                                className="px-3 py-1 bg-[#5711FB]/10 rounded-full text-sm text-[#5711FB]"
                              >
                                {skill}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 px-4 bg-gradient-to-b from-transparent to-white/5">
        <div className="container mx-auto max-w-6xl">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
              More Than Just a Profile
            </h2>
            <p className="text-xl text-gray-600">
              Experience the next evolution of professional networking
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                title: "AI-Powered Job Matching",
                description: "Our AI analyzes your profile to find perfect job matches, considering both hard skills and cultural fit.",
                icon: "🎯"
              },
              {
                title: "Dynamic Profile Optimization",
                description: "Your profile automatically adapts to highlight relevant experience for each job application.",
                icon: "✨"
              },
              {
                title: "Custom CV Generation",
                description: "Export your profile as a beautifully formatted CV, tailored for each specific job application.",
                icon: "📄"
              },
              {
                title: "Curated Professional Network",
                description: "Connect with professionals in a clean, ad-free environment focused on meaningful interactions.",
                icon: "🤝"
              },
              {
                title: "Interview Preparation",
                description: "Get AI-powered insights on how to present your experience for specific roles.",
                icon: "🎓"
              },
              {
                title: "Exclusive Opportunities",
                description: "Access carefully vetted job opportunities from companies that value quality over quantity.",
                icon: "💎"
              }
            ].map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white/90 backdrop-blur-lg rounded-xl p-6 shadow-md hover:shadow-lg transition-shadow"
              >
                <div className="text-4xl mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold text-gray-900 mb-3">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Waitlist CTA Section */}
      <section className="py-20 px-4 bg-gray-50">
        <div className="container mx-auto max-w-3xl text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="space-y-8"
          >
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900">
              Join the Private Beta
            </h2>
            <p className="text-xl text-gray-600">
              Be among the first to experience the future of professional networking.
            </p>

            <form
              onSubmit={handleSubmit}
              className="space-y-4"
            >
              <div className="flex flex-col sm:flex-row gap-4 max-w-xl mx-auto">
                <input
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  className="flex-1 px-6 py-3 rounded-full bg-white border border-gray-200 text-gray-900 focus:outline-none focus:border-[#5711FB] focus:ring-1 focus:ring-[#5711FB]"
                  required
                  disabled={isLoading}
                />
                <motion.button
                  type="submit"
                  whileHover={{ scale: isLoading ? 1 : 1.05 }}
                  whileTap={{ scale: isLoading ? 1 : 0.95 }}
                  className={`px-8 py-3 bg-[#5711FB] hover:bg-[#4600d1] rounded-full text-white flex items-center justify-center gap-2 transition-colors ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center gap-2">
                      <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Joining...
                    </div>
                  ) : (
                    <>Join Beta Waitlist <FiArrowRight /></>
                  )}
                </motion.button>
              </div>

              {error && (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="text-red-400 text-sm text-center"
                >
                  {error}
                </motion.p>
              )}

              {success && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="space-y-4"
                >
                  <p className="text-green-600">
                    Thanks for joining! We'll be in touch soon.
                  </p>
                  <div className="text-gray-600">
                    <p>Help us spread the word!</p>
                    <div className="flex justify-center gap-4 mt-4">
                      <motion.a
                        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                          'Just joined the waitlist for @letsroll_ai - the next generation of professional networking! 🚀'
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        whileHover={{ scale: 1.05 }}
                        className="text-[#11A9FB] hover:text-[#5711FB] transition-colors"
                      >
                        Share on Twitter
                      </motion.a>
                      <motion.a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                          'https://letsroll.ai'
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        whileHover={{ scale: 1.05 }}
                        className="text-[#11A9FB] hover:text-[#5711FB] transition-colors"
                      >
                        Share on LinkedIn
                      </motion.a>
                    </div>
                  </div>
                </motion.div>
              )}
            </form>
          </motion.div>
        </div>
      </section>
      <section className="py-20 px-4 bg-white">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="container mx-auto max-w-3xl text-center"
        >
          <p className="text-xl text-gray-600 mb-2">
            Got ideas, questions, or want to collaborate on this project? I'd love to hear from you!
          </p>
          <motion.a
            href="https://www.linkedin.com/in/balach/"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="inline-flex items-center gap-2 px-6 py-1 text-[#5711FB] text-base font-medium transition-colors"
          >
            Connect with Balach on LinkedIn, for now ;)
          </motion.a>
        </motion.div>
      </section>
      <CookieNotice />
    </div>
  );
}

// Main page component
export default function Page() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SearchParamsComponent />
    </Suspense>
  )
}
